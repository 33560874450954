var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('div',[_c('v-alert',{staticClass:"alert",class:{'alert-error':_vm.alertType=='error', 'alert-success':_vm.alertType=='success'},attrs:{"border":"left","dark":"","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 ml-auto mr-auto"},[_c('v-container',[_c('div',{staticClass:"mb-4 mt-8 d-flex "},[_c('div',{staticClass:"col-md-11"},[_c('h1',[_vm._v("Exit Benefits")])]),_c('div',{staticClass:"col-md-1"},[_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Submit',"label":'+'},on:{"onClick":_vm.Add}})],1)]),_c('div',{staticClass:"col-md-12 ml-auto mr-auto"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchPersonnel),callback:function ($$v) {_vm.searchPersonnel=$$v},expression:"searchPersonnel"}})],1),_c('v-data-table',{attrs:{"loading-text":"Loading data. Please wait....","loading":_vm.loading,"headers":_vm.headers,"search":_vm.searchPersonnel,"items":_vm.exitBenefits,"single-select":false,"item-key":"requestId"},scopedSlots:_vm._u([{key:"item.benefitsLiableToTax",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.benefitsLiableToTax))+" ")])]}},{key:"item.relief",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.relief))+" ")])]}},{key:"item.taxableIncome",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.taxableIncome))+" ")])]}},{key:"item.paye",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.paye))+" ")])]}},{key:"item.terminalBenefitItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"blue"},on:{"click":function($event){$event.preventDefault();return _vm.viewDateModal(item)}}},[_vm._v(" View ")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('h3',[_vm._v("Terminal BenefitItems")])]),_c('v-divider',{staticClass:"mx-4 mb-5"}),_c('v-card-text',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.terminalBenefitItemHeader,"items":_vm.terminalBenefitItems},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dateDialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('h3',[_vm._v("Do you want to delete ?")])]),_c('v-divider',{staticClass:"mx-4 mb-5"}),_c('v-card-actions',[_c('v-spacer'),_c('Button',{staticClass:"mr-4",attrs:{"label":'Yes',"btnType":'Submit'},on:{"onClick":_vm.deleteOk}}),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("No")])],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }